<template>
	<div data-route>
		<page-header
			heading="Teaching"
		/>
		<div data-element="main">
			<list-view :list="list" />
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isTeacherOnly": true
		}
	}
</route>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import ListView    from '@/components/ui/views/listView/ListView';

	export default {
		metaInfo: {
			title: 'Teaching'
		},
		components: {
			PageHeader,
			ListView
		},
		computed: {
			list () {
				const teacherId = this.$store.getters['auth/getTeacherId'];
				return [
					(teacherId ?
						{
							text: 'See teacher profile',
							route: `/teachers/${teacherId}`
						} : false
					),
					{
						text: 'Courses',
						route: '/teaching/courses'
					},
					{
						text: 'Deal library',
						route: '/teaching/deals'
					},
					{
						text: 'Payment Settings',
						route: '/teaching/payment'
					},
					{
						text: 'Transactions',
						route: '/teaching/transactions'
					},
					{
						text: 'Students',
						route: '/teaching/students'
					},
					{
						text: 'Options',
						route: '/teaching/options'
					}
				].filter(Boolean);
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					}
				];
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		}
	};

</script>
